import React from "react";
import moment from "moment";
import "../../Overview/filters.css";
import { Grid, Typography } from "@material-ui/core";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Chip from "@mui/material/Chip";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DateRangePicker from "../../../../../commonComponents/RetailCalendarDatePicker";
import FormControl from "@mui/material/FormControl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./timeline.css";
import LoadingOverlay from "../../../../../commonComponents/loadingOverlay";
import {
  getTimelineDates,
  getTimelineDatesCustom,
  getComapredToYears,
  setFilterChanged,
} from "../../../../../actions/mondaySmartActions";

class TimeLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isApiLoading: false,
    };
  }
  handleSwitch = (prop) => {
    this.props.dispatch({
      type: "HANDLE_SWITCH",
      payload: {
        prop,
      },
    });
  };

  handleButtonClick = async (prop1, prop2) => {
    this.setState({
      isApiLoading: true,
    });

    if (!(prop2 == "Custom")) {
      let resp = await this.props.getTimelineDates(prop2);
      let lastYear;
      if (this.props.comparedTo === "LY") {
        lastYear =
          Number(resp.data.data.date_fw_details.end_date.split("-")[0]) - 1;
      } else if (this.props.comparedTo === "LLY") {
        lastYear =
          Number(resp.data.data.date_fw_details.end_date.split("-")[0]) - 2;
      }
      let startFw = resp.data.data.date_fw_details.start_fw;
      let endFw = resp.data.data.date_fw_details.end_fw;
      if (this.props.comparedTo === "LY" || this.props.comparedTo === "LLY") {
        this.props.getComapredToYears(lastYear, startFw, endFw);
      }
    }
    this.props.setFilterChanged(true)
    this.props.dispatch({
      type: "HANDLE_BUTTON_CLICK",
      payload: {
        prop1,
        prop2,
      },
    });

    this.setState({
      isApiLoading: false,
    });
  };

  handleRadioCheck = (value) => {
    this.props.setFilterChanged(true)
    this.props.dispatch({
      type: "HANDLE_RADIO_CHECK",
      payload: {
        value,
      },
    });
  };

  handleDateChange = async (prop1, value) => {
    this.setState({
      isApiLoading: true,
    });
    this.props.setFilterChanged(true)
    if (value.endDate !== undefined && value.endDate !== "") {
      if (!(value.startDate == null && value.endDate == null)) {
        let resp = await this.props.getTimelineDatesCustom(
          moment(value.startDate).format("YYYY/MM/DD"),
          moment(value.endDate).format("YYYY/MM/DD")
        );
        let lastYear;
        if (this.props.comparedTo === "LY") {
          lastYear = Number(resp.data.data.date_fw_details.end_fw.toString().substr(0,4)) -1
          // lastYear =
          //   Number(resp.data.data.date_fw_details.end_date.split("-")[0]) - 1;
        } else if (this.props.comparedTo === "LLY") {
          lastYear = Number(resp.data.data.date_fw_details.end_fw.toString().substr(0,4)) -2
          // lastYear =
          //   Number(resp.data.data.date_fw_details.end_date.split("-")[0]) - 2;
        }
        let startFw = resp.data.data.date_fw_details.start_fw;
        let endFw = resp.data.data.date_fw_details.end_fw;
        if (this.props.comparedTo === "LY" || this.props.comparedTo === "LLY") {
          this.props.getComapredToYears(lastYear, startFw, endFw);
        }
      }
    }
    this.props.dispatch({
      type: "HANDLE_DATE_CHANGE",
      payload: {
        prop1,
        value,
      },
    });

    this.setState({
      isApiLoading: false,
    });
  };

  render() {
    var labels = {};
    if (this.props.page == "overView") {
      if (this.props.labels.overViewLabels) {
        labels = this.props.labels.overViewLabels;
      }
    }
    if (this.props.page == "deepDive") {
      if (this.props.labels.deepDiveLabels) {
        labels = this.props.labels.deepDiveLabels;
      }
    }
    const isLabels = Object.keys(labels).length;

    return (
      <Grid className="timeline-outer-container" xs={5} md={5} lg={5}>
        <LoadingOverlay
          active={this.props.isApiLoading || this.state.isApiLoading}
        />
        <div className="timeline-outer-container-two">
          <Typography>
            Timeline:{" "}
            <Chip
              size="small"
              label={this.props.timeLine.toUpperCase()}
              variant="outlined"
              sx={{ borderRadius: "0px", border: "0.2px solid lightgray" }}
            />
          </Typography>
          <ModeEditIcon
            onClick={() => this.handleSwitch("showTimeLineButtons")}
            color="primary"
          />
        </div>
        <div className="timeline-border-gray"></div>
        {this.props.showTimeLineButtons && isLabels && (
          <div style={{ padding: "10px" }}>
            <ButtonGroup
              sx={{ backgroundColor: "#e9eff7" }}
              variant="text"
              aria-label="outlined primary button group"
              fullWidth
              size="small"
              disableElevation
            >
              <Button
                sx={{
                  color: "black",
                  backgroundColor:
                    this.props.timeLine == "lw" ? "lightgray" : "#e9eff7",
                }}
                onClick={() =>
                  this.handleButtonClick(
                    "timeLine",
                    (labels != undefined
                      ? labels[0].timeline[0].label
                      : ""
                    ).toLowerCase()
                  )
                }
              >
                {labels != undefined ? labels[0].timeline[0].label : ""}
              </Button>
              <Button
                sx={{
                  color: "black",
                  backgroundColor:
                    this.props.timeLine == "ytd" ? "lightgray" : "#e9eff7",
                }}
                onClick={() =>
                  this.handleButtonClick(
                    "timeLine",
                    (labels != undefined
                      ? labels[0].timeline[1].label
                      : ""
                    ).toLowerCase()
                  )
                }
              >
                {labels != undefined ? labels[0].timeline[1].label : ""}
              </Button>
              <Button
                sx={{
                  color: "black",
                  backgroundColor:
                    this.props.timeLine == "qtd" ? "lightgray" : "#e9eff7",
                }}
                onClick={() =>
                  this.handleButtonClick(
                    "timeLine",
                    (labels != undefined
                      ? labels[0].timeline[2].label
                      : ""
                    ).toLowerCase()
                  )
                }
              >
                {labels != undefined ? labels[0].timeline[2].label : ""}
              </Button>
              <Button
                sx={{
                  color: "black",
                  backgroundColor:
                    this.props.timeLine == "mtd" ? "lightgray" : "#e9eff7",
                }}
                onClick={() =>
                  this.handleButtonClick(
                    "timeLine",
                    (labels != undefined
                      ? labels[0].timeline[3].label
                      : ""
                    ).toLowerCase()
                  )
                }
              >
                {labels != undefined ? labels[0].timeline[3].label : ""}
              </Button>
              <ButtonGroup
                size="small"
                fullWidth
                sx={{ backgroundColor: "white" }}
              >
                <Button
                  sx={{
                    color: "black",
                    backgroundColor:
                      this.props.timeLine == "Custom"
                        ? "//#region 007FFF"
                        : "#e9eff7",
                  }}
                  onClick={() => this.handleButtonClick("timeLine", "Custom")}
                >
                  Custom
                </Button>
              </ButtonGroup>
            </ButtonGroup>
          </div>
        )}
        {this.props.timeLine === "Custom" && (
          <div
            style={{
              padding: "10px",
            }}
          >
            <Grid container>
              <Grid item xs={5} sm={5} lg={5}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={this.props.dateOrWeek}
                      control={
                        <Radio
                          onChange={() => this.handleRadioCheck("week")}
                          checked={this.props.dateOrWeek === "week"}
                          size="small"
                        />
                      }
                      label="Week"
                    />
                    {/* <FormControlLabel value={this.props.dateOrWeek} control={<Radio onChange={() => this.handleRadioCheck('date')} checked={this.props.dateOrWeek === 'date'} size="small" />} label="Date" /> */}
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={7} m={7} lg={7}>
                {this.props.dateOrWeek === "date" && (
                  <div>
                    <DateRangePicker
                      disabled={false}
                      startDate={this.props.startDate}
                      endDate={this.props.endDate}
                      startDateId={`startDate_${1}`}
                      endDateId={`endDate_${2}`}
                      onDatesChange={(value) =>
                        this.handleDateChange("date", value)
                      }
                      calendarRange={null}
                      disableOldWeeks={false}
                      disableFutureWeeks={false}
                      filterType={"dateRange"}
                      disableStartDate={false}
                      pickJustDate={true}
                    />
                  </div>
                )}
                {this.props.dateOrWeek === "week" && (
                  <div>
                    <DateRangePicker
                      disabled={false}
                      startDate={this.props.weekStartDate}
                      endDate={this.props.weekEndDate}
                      startDateId={`startDate_${1}`}
                      endDateId={`endDate_${2}`}
                      onDatesChange={(value) =>
                        this.handleDateChange("week", value)
                      }
                      calendarRange={null}
                      disableOldWeeks={false}
                      disableFutureWeeks={false}
                      filterType={"dateRange"}
                      disableStartDate={false}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    showTimeLineButtons: store.mondaySmartReducer.showTimeLineButtons,
    timeLine: store.mondaySmartReducer.timeLine,
    dateOrWeek: store.mondaySmartReducer.dateOrWeek,
    endDate: store.mondaySmartReducer.endDate,
    startDate: store.mondaySmartReducer.startDate,
    weekStartDate: store.mondaySmartReducer.weekStartDate,
    weekEndDate: store.mondaySmartReducer.weekEndDate,
    labels: store.mondaySmartReducer.labels,
    comparedTo: store.mondaySmartReducer.comparedTo,
    filterChanged: store.mondaySmartReducer.filterChanged,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getTimelineDates: (data) => dispatch(getTimelineDates(data)),
  setFilterChanged: (value) => dispatch(setFilterChanged(value)),
  getComapredToYears: (year, startfw, endfw) =>
    dispatch(getComapredToYears(year, startfw, endfw)),
  getTimelineDatesCustom: (start, end) =>
    dispatch(getTimelineDatesCustom(start, end)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TimeLine));
