/**
 * BASE based on the production or development run
 */
export const BASE_API =
  process.env.NODE_ENV === "development"
    ? "https://vb-dev.mondaysmart.iaproducts.ai/api/v2"
    : // "https://puma.devs.impactsmartsuite.com/api/v2"
      // "http://localhost:8080/api/v2"
      "/api/v2";
/**
 * Platform API goes below
 */
// websocketURl

export const WEBSOCKET_URL =
  process.env.NODE_ENV === "development"
    ? `ws://localhost:8080/api/v2/notifications/connect?channels=channel:1,channel:2&`
    : `wss://${window.location.host}/api/v2/notifications/connect?channels=channel:1,channel:2&`;

export const ENDPOINT =
  process.env.NODE_ENV === "development"
    ? `https://vb-dev.mondaysmart.iaproducts.ai/api`
    : `https://vb-dev.mondaysmart.iaproducts.ai/api`;
//please create api based on structure of below attribute api

console.log("TESTTTT", process.env);
export const FETCH_TENANT = "/tenant/google_tenant_id";
export const VERIFY_TOKEN = "/user/verify-token";
export const USER_ROLES = "/user/userrole";
export const USER_DETAILS = "/user/userdetail";
export const USER_PERMISSIONS = "/user/permissions";
export const USER_GROUPS = "/user/groups";
export const USER_ROLE_GROUP_MAP = "/user/map_user_role_group";

export const FILTER_ELEMENTS = "/common/filter_elements";
export const FILTER_PRODUCT_DETAILS = "/product/product_details";

export const POSTHOG_API = "https://posthog-dev.iaproducts.ai";

/**
 * Attribute Smart API goes below
 */

export const AS_BASE = "/attribute-smart";

//Dashboard API
export const AS_DASHBOARD_UPLOAD = AS_BASE + "/upload/";
export const AS_DASHBOARD_EXPORT = AS_BASE + "/export_product_attributes/";
export const AS_DASHBOARD_ERROR_REPORT = AS_BASE + "/export-image-error/";

//Explore Summary API
export const AS_EXPLORE_SUMMARY = AS_BASE + "/explore-api/";

//Explore category API
export const AS_EXPLORE_CATEGORY = AS_BASE + "/explore-category-api/";
export const AS_EXPLORE_VARIANT_CATEGORY =
  AS_BASE + "/explore-variant-category/";
export const AS_EXPLORE_FILTER = AS_BASE + "/explore-category-filter-api/";
export const AS_EXPLORE_VARIANT_FILTER =
  AS_BASE + "/explore-variant-category-filter/";
export const AS_SINGLE_EDIT_SAVE = AS_BASE + "/product_attributes/";
export const AS_FETCH_ATTRIBUTES = AS_BASE + "/fetch_tenant_attributes/";

//Product Detail Page
export const AS_PRODUCT_DETAIL = AS_BASE + "/product-view/";

//Workflow Page API
export const TRANSITION = "/workflow/transitionmeta/";
export const WORKFLOW = "/workflow/workflow/";
export const STATES = "/workflow/state/";
export const TRANSITIONAPPROVAL = "/workflow/transitionapproval/";

// Ticketing API
export const TICKETING = "/ticket/";
export const UPDATE_STATUS = "/ticket/update_status/";
export const TICKETING_SUPTYPE = "/ticket/subtype/";
export const TICKETING_TYPE = "/ticket/type/";

// Column API
export const GET_COLUMNS = "/core/table-fields";
export const SET_ALL_DATA = "/common/post_all_data";

//Filter API
export const MODEL_FIELDS = "/common/model_fields_properties";
export const SET_FILTER_ELEMENTS = "/common/filter_elements";

//Product Filter
export const GET_ALL_FILTER = "/core/filter-configuration/screen";
export const GET_FILTER_OPTIONS = "/core/attribute-filter";
export const GET_TABLE_DATA = "/master/dimension-table";
export const GET_TABLE_DATA_AGGREGATE = "/master/dimension-table/aggregate";
export const VALIDATE_MAPPING = "/master/map-style";

//
export const SET_ALL_TABLE_DATA = "/master/dimension-table/set-all";

export const GET_PRODUCT_GROUPS = "/core/group/product";

export const MAP_STORE_TO_PRODUCT = "/store-mapping/store-to-product";
export const GET_STORE_TO_DC = "/store-mapping/store-dc";
export const GET_STORE_TO_FC = "/store-mapping/store-fc";
export const GET_ALL_FC = "/master/fc";
export const GET_ALL_DC = "/master/dc";

export const MAP_STORE_TO_FC = "/store-mapping/map-store-to-fc";
export const MAP_STORE_TO_DC = "/store-mapping/map-store-to-dc";
export const GET_REF_STORES = "/store-mapping/ref-stores";
export const GET_STORES_Product = "/store-mapping/products";
export const GET_STORES_GROUPS_Product = "/store-group-mapping/products";
export const POST_STORES_GROUP_TO_PRODUCT =
  "/store-group-mapping/store-group-to-product";
export const POST_STORES_TO_PRODUCT = "/store-mapping/store-to-product";
export const UNMAPPED_STORE_PRODUCT = "/store-mapping/unmapped-stores";
export const UNMAPPED_STORE_GROUP_PRODUCT =
  "/store-group-mapping/unmapped-stores";
export const GET_MAPPED_PRODUCT_STORE = "/store-mapping/products/";
export const GET_MAPPED_PRODUCT_STORE_GROUP = "/store-group-mapping/products/";

// Inventory
export const DC_LIST = "/master/dc-list";
export const INVENTORY_OVERALL_VIEW = "/master/overall-view";
export const INVENTORY_DC_VIEW = "/master/dc-view";
export const INVENTORY_DC_STORE_VIEW = "/master/dc-store-view";
export const INVENTORY_STORE_VIEW = "/master/store-view";
export const INVENTORY_PRODUCT_VIEW = "/master/product-view";
export const GET_PRODUCT_TO_DC = "/product-mapping/product-dc";
export const GET_PRODUCT_TO_FC = "/product-mapping/product-fc";
export const MAP_PRODUCT_TO_DC = "/product-mapping/map-product-dc";
export const MAP_PRODUCT_TO_FC = "/product-mapping/map-product-fc";

//event configuration
export const GET_EVENTS = "/notifications/event";
export const GET_EVENT_TYPE = "/notifications/event/types-triggers";
export const GET_APP_SCREENS = "/notifications/event/application-screens";
export const GET_APP_SCREENS_ACTIONS = "/notifications/event/screen-actions";
export const GET_DEPARTMENTS_CHANNEL = "/notifications/event/filter";
