import React from "react";
import "../../Overview/filters.css";
import { Grid, Typography } from "@material-ui/core";
import { cloneDeep } from "lodash";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Modal from "../../../../../commonComponents/modal";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import MenuItem from "@mui/material/MenuItem";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getDropdowns,
  setDropdowns,
  setDropdownsDownFiletrs,
  setFilterChanged
} from "../../../../../actions/mondaySmartActions";
import LoadingOverlay from "../../../../../commonComponents/loadingOverlay";
import Add from "@material-ui/icons/Add";

class SelectFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isApiLoading: false,
    };
  }

  componentDidUpdate() {
    // CDU
  }

  componentDidMount() {
    // CDM
  }

  getFormattedList(obj) {
    const regionObject = obj.data.data.attribute;
    const list = [];
    regionObject.forEach((thisRegion) => {
      list.push(thisRegion["attribute"]);
    });
    return list;
  }

  handleChange = async (prop1, prop2, prop3) => {
    // prop1- selectedlo_name
    // prop2- value of selectedlo_name
    // prop3 -selectedStoreFilter
    this.props.setFilterChanged(true)
    const allKeys = Object.keys(this.props[prop3]);
    const newBelowValues = {};
    const selectedProp = prop1.slice(8);
    allKeys.forEach((thisKey) => {
      if (allKeys.indexOf(selectedProp) < allKeys.indexOf(thisKey)) {
        newBelowValues[`selected${thisKey}`] = "";
      }
    });

    this.props.dispatch({
      type: "SET_BELOW_FILTERS_EMPTY",
      payload: newBelowValues,
    });
    this.setState({
      isApiLoading: true,
    });
    var type = "";
    if (prop3 === "selectedProductfilters") {
      type = "product";
    } else if (prop3 === "selectedStorefilters") {
      type = "store";
    }

    const maps = Object.keys(this.props[prop3]);
    const keysNo = maps.length; // 5
    var filters = [];
    var startNo = 0;

    // Getting all applied filters
    // Correct
    for (let i = 0; i < keysNo; i++) {
      const selectedProp = prop1.slice(8); // The one that we are changing
      if (maps[i] == selectedProp) {
        startNo = i;
        if (prop2 != "") {
          // for now selected prop
          filters.push({
            attribute_name: maps[i],
            operator: "in",
            values: [prop2],
            filter_type: "cascaded",
          });
        }
      } else {
        // prop1- selectedlo_name
        // prop2- value of selectedlo_name
        // prop3 -selectedStoreFilter
        const allKeys = Object.keys(this.props[prop3]);
        const idxSelected = allKeys.indexOf(selectedProp);
        const idx = allKeys.indexOf(maps[i]);
        if (
          idx < idxSelected &&
          this.props.selectedFilters[`selected${maps[i]}`] &&
          this.props.selectedFilters[`selected${maps[i]}`] != "" &&
          this.props[prop3][maps[i]]
        ) {
          filters.push({
            attribute_name: maps[i],
            operator: "in",
            values: [this.props.selectedFilters[`selected${maps[i]}`]],
            filter_type: "cascaded",
          });
        }
      }
    }

    let productFilters = cloneDeep(this.props.selectedProductfilters);
    Object.keys(productFilters).forEach((key) => {
      productFilters[key] = true;
    });

    let storeFilters = cloneDeep(this.props.selectedStorefilters);
    Object.keys(storeFilters).forEach((key) => {
      storeFilters[key] = true;
    });

    let mapper = type == "product" ? productFilters : storeFilters;

    const requestBody = [];
    for (let i = startNo + 1; i < keysNo; i++) {
      if (this.props[prop3][maps[i]]) {
        if (maps[i] == "store_code") {
          requestBody.push({
            attribute_name: maps[i],
            filter_type: "cascaded",
            filters: filters,
            meta: {
              range: [],
              sort: [],
              search: [],
              limit: {
                limit: null,
                page: null,
              },
            },
            headers: ["string"],
          });
        } else {
          requestBody.push({
            attribute_name: maps[i],
            filter_type: "cascaded",
            filters: filters,
          });
        }
      }
    }

    const requestBodyDownFilters = [];
    for (let i = startNo + 1; i < keysNo; i++) {
      if (mapper[maps[i]]) {
        if (maps[i] == "store_code") {
          requestBodyDownFilters.push({
            attribute_name: maps[i],
            filter_type: "cascaded",
            filters: filters,
            meta: {
              range: [],
              sort: [],
              search: [],
              limit: {
                limit: null,
                page: null,
              },
            },
            headers: ["string"],
          });
        } else {
          requestBodyDownFilters.push({
            attribute_name: maps[i],
            filter_type: "cascaded",
            filters: filters,
          });
        }
      }
    }

    for (let i = 0; i <= requestBody.length - 1; i++) {
      let response = await this.props.getDropdowns(type, requestBody[i]);
      const formattedResonse = this.getFormattedList(response);
      this.props.setDropdowns(formattedResonse, requestBody[i].attribute_name);
    }

    for (let i = 0; i <= requestBodyDownFilters.length - 1; i++) {
      let response = await this.props.getDropdowns(
        type,
        requestBodyDownFilters[i]
      );
      this.props.setDropdownsDownFiletrs(
        response.data.data.attribute,
        requestBodyDownFilters[i].attribute_name
      );
    }

    this.setState({
      ...this.state,
      isApiLoading: false,
    });

    this.props.dispatch({
      type: "UPDATE_SELECTED_FILTER",
      payload: {
        prop1,
        prop2,
      },
    });
  };
  handleSwitch = (prop) => {
    this.props.setFilterChanged(true)
    this.props.dispatch({
      type: "HANDLE_SWITCH",
      payload: {
        prop,
      },
    });
  };
  handleFilterSwitch = (prop1, prop2) => {
    this.props.setFilterChanged(true)
    const keys = Object.keys(this.props[prop1]);
    const newAboveValues = {};
    keys.forEach((thisKey) => {
      if (keys.indexOf(prop2) > keys.indexOf(thisKey)) {
        newAboveValues[`selected${thisKey}`] = "";
      }
    });
    var asi = 0;
    for (let i = 0; i < keys.length; i++) {
      if (this.props[prop1][keys[i]]) {
        asi = i;
      }
    }
    const its = keys.indexOf(prop2);
    const isAlreadySelected = this.props[prop1][prop2] ? true : false;
    this.props.dispatch({
      type: "SET_ABOVE_FILTERS_EMPTY",
      payload: newAboveValues,
    });
    this.props.dispatch({
      type: "SET_DROPDOWN_DOWN_FILTERS_EMPTY",
      payload: {},
    });
    this.props.dispatch({
      type: "HANDLE_FILTER_SWITCH",
      payload: {
        prop1,
        prop2,
        asi,
        its,
        isAlreadySelected,
      },
    });
  };
  getMenu = (filter) => {
    // Here Filter is the name of the menu
    const options = [];
    const filterArray = this.props.dropDowns[filter]
      ? this.props.dropDowns[filter]
      : [];
    filterArray.forEach((thisOption) => {
      options.push(
        <option style={{ color: "#65788B", height: "30px" }} value={thisOption}>
          {thisOption}
        </option>
      );
    });
    return options;
  };

  getCascadedFilters = (prop) => {
    if (this.props.selectedProductfilters == undefined) {
      return [];
    }
    if (this.props.selectedStorefilters == undefined) {
      return [];
    }

    var selectedFilters = {};
    if (prop === "selectedProductfilters") {
      selectedFilters = this.props.selectedProductfilters;
    }
    if (prop === "selectedStorefilters") {
      selectedFilters = this.props.selectedStorefilters;
    }

    const filters = Object.keys(selectedFilters);
    // Get last selected in filters 'i'
    for (var i = filters.length - 1; i >= 0; i--) {
      if (selectedFilters[filters[i]]) {
        break;
      }
    }
    const selections = [];
    if (i != -1) {
      for (let j = 0; j <= i; j++) {
        // Displaying Selects for i selected fropdowns
        selections.push(
          <FormControl style={{ marginTop: "5px" }}>
            <label style={{ fontSize: "small" }}>
              {this.props.labelMapper[filters[j]]}
            </label>
            <select
              style={{
                top: "561px",
                left: "674px",
                width: "188px",
                height: "35px",
                /* UI Properties */
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #A5B6C7",
                borderRadius: "3px",
                opacity: 1,
                marginRight: "10px",
              }}
              onChange={(e) =>
                this.handleChange(`selected${filters[j]}`, e.target.value, prop)
              }
              name={this.props.labelMapper[filters[j]]}
              value={this.props.selectedFilters[`selected${filters[j]}`]}
              id={`${j}-selct-input`}
            >
              <option style={{ color: "gray" }} value={""}>
                {"All"}
              </option>
              {this.getMenu(filters[j])}
            </select>
          </FormControl>
        );
      }
    }
    return selections;
  };

  getDialog(prop1, prop2, label) {
    const boxes = [];
    const keys = Object.keys(this.props[prop2]);
    keys.forEach((thisKey, index) => {
      if (index !== 0) {
        boxes.push(
          <div
            style={{ flexBasis: "40%" }}
            className={
              this.props[prop2][thisKey] ? "selectedButton" : "unselectedButton"
            }
            onClick={() => this.handleFilterSwitch(prop2, thisKey)}
          >
            <span
              style={{
                lineHeight: "45px",
              }}
            >
              {this.props.labelMapper[thisKey]}
            </span>
          </div>
        );
      }
    });
    return (
      <Modal onClose={() => this.handleSwitch(prop1)}>
        <div style={{ width: "20", backgroundColor: "white", padding: "20px" }}>
          <h4>{label}</h4>
          <div
            style={{
              display: "flex",
              "flex-wrap": "wrap",
              justifyContent: "space-around",
            }}
          >
            {boxes}
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    var dialogParams = {};
    var showDialog = false;
    var cascadedParam = "";
    var handleSwitchParam = "";
    var isLastSelected = false;
    dialogParams = [
      "showMerchandizingModal",
      "selectedProductfilters",
      "Merchandizing Filter",
    ];
    showDialog = this.props.showMerchandizingModal;
    cascadedParam = "selectedProductfilters";
    handleSwitchParam = "showMerchandizingModal";
    if (this.props.heading === "Merchandizing Filter") {
      dialogParams = [
        "showMerchandizingModal",
        "selectedProductfilters",
        "Merchandizing Filter",
      ];
      showDialog = this.props.showMerchandizingModal;
      cascadedParam = "selectedProductfilters";
      handleSwitchParam = "showMerchandizingModal";
      if (this.props[cascadedParam]["l3_name"]) {
        isLastSelected = true;
      }
    }
    if (this.props.heading === "Geographic Filter") {
      dialogParams = [
        "showRegionModal",
        "selectedStorefilters",
        "Geographic Filter",
      ];
      showDialog = this.props.showRegionModal;
      cascadedParam = "selectedStorefilters";
      handleSwitchParam = "showRegionModal";
      if (this.props[cascadedParam]["store_code"]) {
        isLastSelected = true;
      }
    }

    return (
      <React.Fragment>
        <LoadingOverlay
          active={this.props.isApiLoading || this.state.isApiLoading}
        />
        <Grid
          style={{ height: "100%", marginRight: "10px" }}
          xs={5}
          md={5}
          lg={5}
        >
          {showDialog && this.getDialog(...dialogParams)}
          <div
            style={{
              border: "0.5px solid lightgray",
              borderRadius: "5px",
              padding: "10px",
              backgroundColor: "white",
            }}
          >
            <div style={{ fontWeight: "500" }}>{this.props.heading}</div>
            {this.getCascadedFilters(cascadedParam)}
            {isLastSelected && (
              <RemoveIcon
                onClick={() => this.handleSwitch(handleSwitchParam)}
                sx={{ backgroundColor: "#e9eff7", marginTop: "30px" }}
              />
            )}
            {!isLastSelected && (
              <AddIcon
                onClick={() => this.handleSwitch(handleSwitchParam)}
                sx={{ backgroundColor: "#e9eff7", marginTop: "30px" }}
              />
            )}
          </div>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  const { mondaySmartReducer } = store;
  return {
    showMerchandizingModal: mondaySmartReducer.showMerchandizingModal,
    showRegionModal: mondaySmartReducer.showRegionModal,
    selectedProductfilters: mondaySmartReducer.selectedProductfilters,
    selectedStorefilters: mondaySmartReducer.selectedStorefilters,
    dropDowns: mondaySmartReducer.dropDowns,
    selectedFilters: mondaySmartReducer.selectedFilters,
    labelMapper: mondaySmartReducer.labelMapper,
    filterChanged: store.mondaySmartReducer.filterChanged,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  // TESTTT
  setFilterChanged: (value) => dispatch(setFilterChanged(value)),
  getDropdowns: (type, payload) => dispatch(getDropdowns(type, payload)),
  setDropdowns: (response, item) => dispatch(setDropdowns(response, item)),
  setDropdownsDownFiletrs: (response, item) =>
    dispatch(setDropdownsDownFiletrs(response, item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SelectFilters));
